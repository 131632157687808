export const resizeImage = (file: File, callback: (resizedFile: File, url: string) => void) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = event => {
    const img = new Image();
    img.src = event.target?.result as string;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const targetWidth = 400;
      const targetHeight = 300;

      canvas.width = targetWidth;
      canvas.height = targetHeight;

      // Определяем масштабирование
      const scale = Math.max(targetWidth / img.width, targetHeight / img.height);

      // Смещение для центрирования
      const scaledWidth = img.width * scale;
      const scaledHeight = img.height * scale;
      const offsetX = (scaledWidth - targetWidth) / 2;
      const offsetY = (scaledHeight - targetHeight) / 2;

      ctx?.drawImage(img, -offsetX, -offsetY, scaledWidth, scaledHeight);

      canvas.toBlob(blob => {
        if (blob) {
          const resizedFile = new File([blob], file.name, { type: file.type });
          callback(resizedFile, canvas.toDataURL());
        }
      }, file.type);
    };
  };
};
