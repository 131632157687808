import { USER_ROLES_ORDER } from 'consts';

export const languages: any = {
  'en-US': {
    name: 'English',
    image: 'UKFlag.png'
  },
  'ko-KR': {
    name: 'Korean',
    image: 'KoreanFlag'
  },
  'ru-RU': {
    name: 'Russian',
    image: 'RussiaFlag.png'
  }
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      typeof reader.result === 'string' ? resolve(reader.result) : reject('error');
    };

    reader.readAsDataURL(file);
  });
};

export const getMainRole = (roles: string[]) => {
  for (const role of USER_ROLES_ORDER) {
    if (roles.includes(role)) return role;
  }
  return 'No roles';
};

export const sortByMainRole = (roles: string[]) => {
  const sortedRoles = [];
  for (const role of USER_ROLES_ORDER) {
    if (roles.includes(role)) sortedRoles.push(role);
  }
  return sortedRoles;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getInitials = function (input: string): string {
  const names = input.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const shiftDate = (date: Date, numDays: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
};

export const getFirstDayOfTheCurrentYear = () => {
  const currentYear = new Date().getFullYear();
  const firstDay = new Date(currentYear, 0, 1);

  return firstDay;
};

export const getLastDayOfTheCurrentYear = () => {
  const currentYear = new Date().getFullYear();
  const lastDay = new Date(currentYear, 11, 31);

  return lastDay;
};

export * from './addMonths';
export * from './calculateExpire';
export * from './classNames';
export * from './cn';
export * from './convertToLocalDate';
export * from './dataUrlToFile';
export * from './findMaximum';
export * from './generatePassword';
export * from './getStepTypeLabel';
export * from './normalizeData';
export * from './resolveUserStatus';
export * from './timestampToISO';
export * from './timestampToInput';
export * from './imageCrop';
